import React from "react"
import _ from "lodash"
import IGdprData from "./IGdpr"
import { loadJs } from "./GdprJsLoader"

type ActionSaveSettings = {
  type: "SAVE_SETTINGS"
  settings: { [index: string]: boolean }
}

type ActionDefaultSettings = {
  type: "DEFAULT"
}

type ActionDialog = {
  type: "HIDE_DIALOG" | "SHOW_DIALOG"
}

type ActionSettings = {
  type: "HIDE_SETTINGS" | "SHOW_SETTINGS"
}

type Action =
  | ActionSaveSettings
  | ActionDefaultSettings
  | ActionDialog
  | ActionSettings

export interface IGdprState {
  settings: IGdprData
  hideDialog: boolean
  hideSettings: boolean
  changed: boolean
  dispatch: (action: Action) => void
}

let initialState: IGdprState = {
  settings: {
    categories: ["Funktion", "Multimedia"],
    services: [
      {
        name: "Datenschutz Einstellungen", // unique name of the service
        // Describes the function and the need of use of the service.
        description:
          "Die hier getätigten Einstellungen über deine Privatsphäre speichern wir Lokal auf Ihrem Computer ab damit Sie beim nächsen Besuch wieder berücksichtigt werden. Die Daten sind nur Lokal gesichert und werden an keine anderen Server übermittelt.",
        allowed: false, // should be always false and will be changed via user interaction
        default: true, // the default value on the users page. If true the checkbox will be selected
        category: "Funktion", // Name of a category. All services with the same string will be put together on the settings page.
        cookies: [{ type: "LocalStorage", name: "gdpr" }], // A list of cookies or local storage properties used by the service
        domain: "his-print-service.de", // the domain communicating with to provide information to the user
      },
      {
        name: "Youtube",
        description:
          'Zum Abspielen unserer Video-Inhalte werden Videos von Youtube auf unsere Seite integriert. Durch den Abruf dieser Videos setzt Youtube Cookies und kann die Verbindung über unsere Seite auswerten. Alle nötigen Informationen finden Sie auf der <a href="https://policies.google.com/privacy?hl=de&gl=de" target="_blank"> Datenschutzseite</a> von Google',
        allowed: false,
        default: true,
        category: "Multimedia",
        cookies: [
          { type: "Cookie", name: "__Secure-3PAPISID" },
          { type: "Cookie", name: "__Secure-3PSID" },
          { type: "Cookie", name: "__Secure-APISID" },
          { type: "Cookie", name: "__Secure-HSID" },
          { type: "Cookie", name: "__Secure-SSID" },
          { type: "Cookie", name: "APISID" },
          { type: "Cookie", name: "CONSENT" },
          { type: "Cookie", name: "HSID" },
          { type: "Cookie", name: "LOGIN_INFO" },
          { type: "Cookie", name: "PREF" },
          { type: "Cookie", name: "s_gl" },
          { type: "Cookie", name: "SAPISID" },
          { type: "Cookie", name: "SIDCC" },
          { type: "Cookie", name: "SID" },
          { type: "Cookie", name: "SSID" },
          { type: "Cookie", name: "VISITOR_INFO1_LIVE" },
          { type: "Cookie", name: "YSC" },
        ],
        domain: "youtube.com",
      },
    ],
  },
  hideDialog: false,
  hideSettings: true,
  changed: false,
  dispatch: () => {},
}

let GdrpContext = React.createContext(initialState)

let reducer = (state: IGdprState, action: Action) => {
  let newState: IGdprState = { ...state }

  switch (action.type) {
    case "SAVE_SETTINGS":
      for (const key in action.settings) {
        for (const serviceKey in newState.settings.services) {
          const service = newState.settings.services[serviceKey]
          if (service.name === key) {
            newState.settings.services[serviceKey].allowed =
              action.settings[key]
          }
        }
      }
      newState.changed = true

      loadJs(newState.settings.services)
      break

    case "DEFAULT":
      for (const key in newState.settings.services) {
        newState.settings.services[key].allowed =
          newState.settings.services[key].default
      }
      newState.changed = true
      loadJs(newState.settings.services)
      break

    case "HIDE_DIALOG":
      newState.hideDialog = true
      break
    case "SHOW_DIALOG":
      newState.hideDialog = false
      break

    case "HIDE_SETTINGS":
      newState.hideSettings = true
      break
    case "SHOW_SETTINGS":
      newState.hideSettings = false
      break
  }

  // Save to LocalStorage
  let allowToLocalStorage: boolean = false
  let lsSettings: { [key: string]: boolean } = {}
  for (const service of newState.settings.services) {
    lsSettings[service.name] = service.allowed
    if (service.name === "Datenschutz Einstellungen" && service.allowed) {
      allowToLocalStorage = true
    }
  }
  if (allowToLocalStorage) {
    localStorage.setItem("gdpr", JSON.stringify(lsSettings))
  }

  return newState
}

const GdprContextProvider = (props: any) => {
  if (props.value) {
    initialState = props.value
  }
  // Read from LocalStorage
  if (localStorage.getItem("gdpr")) {
    const lsSettings = JSON.parse(localStorage.getItem("gdpr"))
    for (const key in lsSettings) {
      for (const serviceKey in initialState.settings.services) {
        if (initialState.settings.services[serviceKey].name === key) {
          initialState.settings.services[serviceKey].allowed = lsSettings[key]
        }
      }
    }
    initialState.changed = true
    initialState.hideDialog = true
  }

  let [state, dispatch] = React.useReducer(reducer, initialState)
  let value = { ...state, dispatch }

  loadJs(value.settings.services)

  return (
    <GdrpContext.Provider value={value}>{props.children}</GdrpContext.Provider>
  )
}

let GdrpContextConsumer = GdrpContext.Consumer

export { GdrpContext, GdprContextProvider, GdrpContextConsumer }
