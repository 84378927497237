import { IGdbrService } from "./IGdpr"

const purgeData = (
  data: Array<{ name: string; type: "LocalStorage" | "Cookie" }>
) => {
  for (const item of data) {
    if (item.type === "Cookie") {
      document.cookie = item.name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
    }

    if (item.type === "LocalStorage") {
      localStorage.removeItem(item.name)
    }
  }
}

export function loadJs(services: Array<IGdbrService>) {
  for (const service of services) {
    // Add a case for each service handling javscript code to add or remove the js files.
    switch (service.name) {
      // Matomo
      case "Analyse":
        if (service.allowed) {
          if (!document.getElementById("matomo")) {
            var u = "https://hisprintservice.matomo.cloud/"
            _paq.push(["setTrackerUrl", u + "matomo.php"])
            _paq.push(["setSiteId", "1"])
            var d = document,
              g = d.createElement("script"),
              s = d.getElementsByTagName("script")[0]
            g.type = "text/javascript"
            g.async = true
            g.defer = true
            g.id = "matomo"
            g.src =
              "https://cdn.matomo.cloud/hisprintservice.matomo.cloud/matomo.js"
            s.parentNode.insertBefore(g, s)
          }
        } else {
          if (document.getElementById("matomo")) {
            document.getElementById("matomo").remove()
            purgeData(service.cookies)
          }
        }
        break

      // RocketChat
      case "Chat":
        if (service.allowed) {
          if (!document.getElementById("chat")) {
            ;(function(w, d, s, u) {
              w.RocketChat = function(c) {
                w.RocketChat._.push(c)
              }
              w.RocketChat._ = []
              w.RocketChat.url = u
              var h = d.getElementsByTagName(s)[0],
                j = d.createElement(s)
              j.async = true
              j.src =
                "https://chat.his-print-service.de/livechat/rocketchat-livechat.min.js?_=201903270000"
              h.parentNode.insertBefore(j, h)
            })(
              window,
              document,
              "script",
              "https://chat.his-print-service.de/livechat"
            )
          }
        } else {
          if (document.getElementById("chat")) {
            document.getElementById("chat").remove()
            document.getElementsByClassName("rocketchat-widget")[0].remove()
            purgeData(service.cookies)
          }
        }

        break

      default:
        if (!service.allowed) {
          purgeData(service.cookies)
        }
        break
    }
  }
}
