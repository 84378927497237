import "./src/styles/theme.scss"
import "./src/styles/global.scss"

import React from "react"

import { GdprContextProvider } from "./src/components/Gdpr/GdprContext"

export const wrapRootElement = ({ element }) => (
  <div>
    <GdprContextProvider>{element}</GdprContextProvider>
  </div>
)
