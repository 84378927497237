module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SMJ-Augsburg","short_name":"SMJ-Augsburg","start_url":"/","background_color":"#121212","theme_color":"#0A0A0A","display":"standalone","icon":"src/images/SMJ-Augsburg-Logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
